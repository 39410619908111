<template>
  <div class="home">

    <div class="box">
    <span class="paper" v-html="paper" style="white-space: pre-wrap;"></span>
    </div>

  <div class="cont">
      <button  v-if="started" @click="delayedReturn(), setText(this.text), printer(-1,this.mode,this.speed,-1)">Text anzeigen</button>
      <button  v-if="finished" @click="backToSurvey()">Fertig</button>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Home-view',
  data(){
    return{
      titleText:"",
      paper:"",
      timer:null,
      started:true,
      finished:false,
      mode:new URLSearchParams(window.location.search).get('mode'), 
      speed:new URLSearchParams(window.location.search).get('speed'),
      text:new URLSearchParams(window.location.search).get('text'),

    }
  },
  mounted(){
  
  },
  methods:{
    printer(i,mode,speed,lastI){
      this.started=false
      this.stoper()
      let char= this.getRandomArbitrary(6,12)
     
        this.timer = setInterval(()=>{

      
            i++
            const x = i%this.titleText.length
            if(this.titleText[x]== "^"){
              this.paper+= "HELP"
            }
            else{
            this.paper += this.titleText[x]
            }
            if(this.paper.length == this.titleText.length){
              this.finished=true
           
              this.stoper()
            }
            //satzbasiert
          if(mode==2){
            if(this.titleText[i] == '.' ||this.titleText[i] == '?'  || this.titleText[i] == '!' ){
              this.delay(this.getRandomArbitrary(500,1000),i,speed,mode,lastI)
            }
          }
         // tokenized
          else if (mode==1){
            console.log(char)
            if(i>=lastI+char && i %char ===0){
              this.delay(this.getRandomArbitrary(50,100),i,speed,mode,i)
            }
          }
      
        },speed)
    },
    stoper(){
      clearInterval(this.timer)
    
    },
    delay(time,i,speed,mode,lastI) {
      clearInterval(this.timer)

      setTimeout(() => {
    this.printer(i,mode,speed,lastI) }, time)
},
getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
},
backToSurvey(){

  clearInterval(this.timer)
  this.finished=false
  this.paper= "Bitte kehren Sie zur Umfrage zurück!"
},
delayedReturn() {

      setTimeout(() => {
    this.backToSurvey() }, 420000)
},
setText(text){
    if(text ==1 ){
        this.titleText="Vor 500 Jahren wurde Florida spanisch \n \n \
Als erster Europäer betrat Juan Ponce de León 1513 Florida und nahm die \n \
vermeintliche Insel für die spanische Krone in Beschlag. Von den «Hispanics» wird er \n \
bis heute als Held verehrt.\n \n\
Drei Schiffe waren es wohl, aber wo genau sie landeten, weiss niemand. Ob in St. \n \
Augustine im Nordosten Floridas oder mehr als 300 Kilometer weiter südlich in \n \
Melbourne - dutzende Ortschaften entlang des Küstenstreifens reklamieren die Stelle \n \
für sich, schliesslich geht es um nichts weniger als ihren \n \
gemeinsamen Ursprung.\n \n\
Von Puerto Rico herkommend näherte sich im März \n \
1513 kurz vor Ostern der spanische Entdecker Juan \n \
Ponce de Léon mit der «Consolación», der «Santiago» \n \
und der «San Cristóbal» dem Südosten Nordamerikas. \n \
Als erster Europäer betrat er vor genau 500 Jahren den \n \
heutigen US-Bundesstaat Florida - und hielt die Gegend \n \
zunächst für eine Insel.\n\n \
Fast genauso umstritten wie der Ort der Anlandung des Entdeckers von Florida war \n \
lange auch das genaue Datum. Inzwischen haben sich Historiker weitgehend auf den \n \
2. April geeinigt, auch wenn einige immer noch von Ende März sprechen und darauf \n \
hinweisen, dass die Quellenlage äusserst dünn ist.\n \n\
«Wir haben überhaupt keine direkten Berichte von der Reise, nur Texte von Menschen, \n \
die viel später darüber geschrieben haben», berichtete der 2008 gestorbene US Historiker \n \
Charles Arnade, der als Experte für die Entdeckung Floridas galt.\n\n \
Feiern in Florida\n\n\
Im Geburtstags-Bundesstaat sind für den 2. April Feste geplant, bei denen unter \n \
anderem Statuen von Ponce de León enthüllt werden sollen. Auf den Namen Florida, \n \
das spanische Wort für blühend, kam der wohl in den 1470er Jahren geborene \n \
Seefahrer der Überlieferung nach wegen der Landschaft, die er sah - und wegen des \n \
anstehenden Osterfestes, das die Spanier auch «Pascua Florida» nennen.\n \n\
Rund 20 Jahre zuvor war Christoph Kolumbus - eigentlich auf der Suche nach einem \n \
Seeweg nach Asien - in der Karibik gelandet und hatte damit in Europa schlagartig \n \
eine Art «Entdeckungs-Fieber» ausgelöst. Zahlreiche Expeditionen folgten.\n \n\
Kolumbus wurde auf einer weiteren Seereise unter anderem auch von Ponce de León \n \
begleitet, der später auch die Karibikinsel Puerto Rico erkundete und 1509 zu ihrem \n \
Gouverneur erklärt wurde. Gerüchte über weitere bis dahin unbekannte Inseln im \n \
Nordosten der Karibik veranlassten den auf Zeichnungen stets mit Spitzbart und Hut \n \
dargestellten Ponce de León, erneut aufzubrechen.\n \n\
Held der Hispanics\n\n \
Dass mit Ponce de León ein Spanier schon so früh Florida entdeckt habe, das sei für \n \
ihn persönlich sehr wichtig, sagt Wilson Camelo, der bei einer Werbeagentur in \n \
Orlando arbeitet. Wie inzwischen rund 16 Prozent der US-Amerikaner ist Camelo \n \
«Hispanic».\n\n \
Laut offizieller Definition der US-Statistikbehörde zählt dazu, wer kubanische, \n \
puertoricanische, südamerikanische, zentralamerikanische oder spanische Wurzeln \n \
hat. Die Hispanics sind die am rasantesten wachsende Volksgruppe in den USA -\n \
zwischen 2000 und 2010 hat sich ihre Zahl fast verdoppelt.\n\n \
«Für uns Hispanics ist Ponce de León heute immer noch ein grosser Held», sagt der in \n \
Kolumbien geborene Camelo, der auch einen Internet-Blog über Hispanics betreibt,\n \
«Er hat quasi unseren Ursprung hier gelegt und das macht einen grossen Teil unserer \n \
Identität und Daseinsberechtigung aus.»\n \n\
Mit seinen Kindern fahre er deshalb regelmässig an die historischen Orte an Floridas \n \
Ostküste, erzählt Camelo: «In der Schule lernen sie immer nur von den Pilgrims und \n \
den britischen Kolonien. Ich will, dass sie auch Ponce de León kennen lernen.»\n\n \
«Quelle der Hoffnung»\n\n \
Glück brachte Florida Ponce de León allerdings nicht. Bei seiner zweiten Reise 1521 \n \
wurde er wahrscheinlich von Ureinwohnern verwundet und starb kurze Zeit später.\n\n \
Mit der heutigen Entwicklung des sonnigen Bundesstaats wäre der Entdecker aber \n \
zufrieden, mutmasst Historiker Arnade: «Florida ist eine Quelle der Hoffnung und des \n \
Reichtums - genau wie er sich das damals erhofft hatte, nur eben ein paar \n \
Jahrhunderte später.»"
      }

      else if(text ==2){
        this.titleText = "Notruf im Riff\n\n\
Steinkorallen haben kleine Fische als Bodyguards, die sie bei drohender Gefahr\n \
über einen speziellen Geruchsstoff herbeirufen. Das Alarmsystem funktioniert \n\
innerhalb von Minuten.\n\n\
Die im Geäst von Steinkorallen lebende Korallengrundel Gobidon histrio ist nur rund\n \
vier Zentimeter klein, hat es aber durchaus in sich. Denn der grün-rot gestreifte \n\
Meeresbewohner verteidigt nicht nur sich, sondern auch seine Umwelt. Er produziert in \n\
seiner Haut einen giftigen Schleim, der Feinde abhält. Gleichzeitig kommt der kleine \n\
Fisch aber auch sofort angeschwommen, wenn die Steinkoralle Hilfe braucht.\n\
Besonders riskant für die Steinkoralle sind zum Beispiel schnell wachsende Algen, die \n\
Korallen in kurzer Zeit überwuchern und letztlich zum Absterben bringen. Jetzt haben \n\
Danielle Dixson und Mark Hay vom Georgia Institute of Technology in Atlanta in\n \
Versuchen im Pazifik herausgefunden, dass Steinkorallen einen bestimmten Duftstoff \n\
absondern, der wie eine Notrufnummer die Grundel alarmiert.\n\
Die bunten Leibwächter machen sich nach dem Erhalt der chemischen Botschaft sofort \n\
auf den Weg und an die Arbeit, indem sie das schädliche Grün auffressen. «Die Fische \n\
mähen es regelrecht ab», erklärt die Post-Doktorandin in einer Mitteilung des Instituts.\n\n\
Nachbarschaftliche Hilfe\n\n\
Im Gegenzug schützt die Steinkoralle die \n\
Grundel und bietet ihr eine sichere Unterkunft \n\
und Nahrung wie Zooplankton oder Algen in \n\
unmittelbarer Nähe. Diese Zusammenarbeit nützt \n\
also beiden und bringt gegenseitige Vorteile. Um \n\
dies genau zu untersuchen, tauchte die \n\
Forscherin vor dem Fidji-Archipel zu einem \n\
Steinkorallenriff. Dort brachte sie die giftige Alge \n\
Chlorodesmis fastigiata in Kontakt mit der Steinkoralle.\n\n\
Dies führte dazu, dass die Koralle sofort den \n\
chemischen Alarm auslöste. Innerhalb von ein \n\
paar Minuten waren die ersten Fische der Art Gobidon histrio zur Stelle und begannen\n \
das Unheil zu bekämpfen. Zur Schutztruppe gehörten auch Vertreter der Grundelart \n\
Paragobidon enchinocephalus, die allerdings die giftige Alge nicht verspeisten, \n\
sondern lediglich zerbissen und Stück und für Stück zerkleinerten.\n\
Wie das amerikanische Forscherteam in der aktuellen Ausgabe der Fachzeitschrift \n\
«Science» berichtet, konnten die Grundeln in drei Tagen bereits rund 30 Prozent der\n\
giftigen Pflanze beseitigen. Mit der Folge, dass die Schäden an der Steinkoralle sich im \n\
Vergleich zum Versuch ohne die nützlichen Fische um 70 bis 80 Prozent reduzierten.\n\
Weiter konnte die Forscherin direkt vor Ort Wasserproben mit dem spezifischen \n\
Alarmstoff der Steinkoralle sammeln, der die Bodyguards herbeiruft. Um die Wirkung \n\
des Geruchs zu belegen, fertigte sie künstliche Algenbüschel aus Nylonschnüren an, \n\
von denen aber nur eines den Duftstoff umgab. Um welche chemische Substanz es \n\
sich dabei handelt, konnten die beiden Korallenexperten bisher mit ihren Versuchen \n\
allerdings noch nicht herausfinden.\n\n\
Die beiden Meeresbiologen warnen, dass dieses ausgetüftelte Kommunikationssystem\n \
wegen der Verschmutzung der Ozeane nicht mehr richtig funktionieren könnte. Hinzu \n\
komme, dass übermässiges Algenwachstum zum Absterben der Korallen führe und \n\
deren Existenz bedrohe.\n\n\
\
Gefährdete Korallenriffe\n\n\
Dies ist bedenklich, weil dieses marine Ökosystem sehr empfindlich auf \n\
Veränderungen wie etwa die Folgen der globalen Klimaerwärmung reagiert. Weltweit \n\
schreitet der Schwund der Korallenriffe mit rasantem Tempo immer weiter voran. Allein \n\
das Great Barrier Reef vor der Nordostküste Australiens ist in den vergangenen 27 \n\
Jahren um über 50 Prozent geschrumpft.\n\n\
Die raffinierte Leibwächtergrundel in bunter Streifenuniform schützt dagegen die \n\
Steinkoralle, indem sie giftiges Kraut einfach wegfrisst. Wie die Wissenschaftlerin\n \
feststellte, macht die Alge die Grundeln sogar noch toxischer. Auf diese Weise sind sie \n\
noch besser gewappnet gegen ihre eigenen Fressfeinde."
      }

      else if(text ==3){
        this.titleText= "Der erbitterte Kampf um Ackerland\n\n\
Der Landraub grosser Gebiete ohne Rücksicht \
auf bisherige Nutzer nimmt massiv zu. \nNeue \
Richtlinien sollen Kleinbauern besser vor \
Grossinvestoren schützen und Konflikte \n\
entschärfen. Das Problem: Sie sind \
unverbindlich.\n\n\
Kleinbauern in Äthiopien, Urvölker in Südamerika, \n\
Bauern in der Ukraine, Reisbauern auf den Philippinen \n\
und sogar Orang-Utans auf Sumatra – sie alle\n \
verbindet dasselbe Schicksal: Das Land, das ihr Überleben sichert, wird verkauft. \n\
Riesige Monokulturen entstehen für Weizen, Ölpalmen, Zuckerrohr, Sojabohnen oder \n\
Jatropha-Nüsse.\n\n\
Die Weltbevölkerung wächst und damit die Nachfrage nach Lebensmitteln. \n\
Biotreibstoffe sollen einen immer grösseren Anteil der Energie liefern, sollen Erdöl \n\
ersetzen. So müssen Fluggesellschaften in den nächsten Jahren grosse Mengen an \n\
Biotreibstoffen zukaufen, um ihre CO2-Bilanzen aufzubessern. Auch dafür sind \n\
Anbaugebiete notwendig. Doch das Land ist begrenzt. Grossinvestoren reissen sich \n\
um die besten landwirtschaftlichen Flächen in aller Welt.\n\n\
Gegner werden umgebracht\n\n\
«Land Grabbing», der Landraub grosser Gebiete ohne Rücksicht auf bisherige Nutzer, \n\
hat in den letzten zehn Jahren massiv zugenommen. In der seit kurzem öffentlich \n\
zugänglichen Datenbank Land Matrix, an deren Entwicklung auch das Centre for \n\
Development and Environment (CDE) der Universität Bern beteiligt ist, werden mehr \n\
als 1200 Transaktionen weltweit dokumentiert. Seit 2000 waren 83 Millionen Hektaren \n\
Land betroffen. Wahrscheinlich waren es viel mehr, denn selten werden die\n \
Einzelheiten solcher Deals bekannt. Die internationale Hilfsorganisation Oxfam schätzt, \n\
dass 227 Millionen Hektaren den Besitzer wechselten – die gesamte \n\
landwirtschaftliche Fläche Europas beträgt 178 Millionen Hektaren.\n\n\
Nicht immer handelt es sich um Landraub, aber oft. «Landerwerb findet in Gebieten mit \n\
einer erheblichen Bevölkerungsdichte statt», sagt Markus Giger vom CDE. Das beste\n\
Land liegt nicht brach. Das führt zu Konflikten zwischen Bauernorganisationen,\n \
internationalen Investoren und Regierungen. Ende April wurde Chut Wutty, ein\n\
bekannter Waldschützer in Kambodscha, von der Militärpolizei erschossen. Im Januar \n\
wurde ein altes Bauernehepaar, das für die Rechte von Kleinbauern in Guatemala \n\
gekämpft hatte, ermordet.\n\n\
Neue Regeln sind freiwillig\n\n\
Um Konflikte zu entschärfen, wurden unlängst in Rom erstmals internationale \n\
Richtlinien für die Landnutzung verabschiedet. Drei Jahre lang hatten Regierungen, \n\
Experten, Industrievertreter und Bauernverbände unter Leitung der \n\
Welternährungsorganisation FAO darüber verhandelt. Das 35 Seiten lange Dokument \n\
nimmt Staaten und Investoren in die Pflicht. Sie sollen traditionelle \n\
Eigentumsstrukturen, die in keinem Grundbuch festgehalten sind, respektieren. Sie\n \
sollen dafür sorgen, dass kein Raubbau an den Ressourcen getrieben wird, dass\n \
Investitionen die Nachhaltigkeit fördern. Klimaschutz, Frauenrechte, Verhandlung statt Konfrontation,\n Respekt für das Gesetz – die Richtlinien sind umfassend.\n\n\
Das Bundesamt für Landwirtschaft BLW begrüsste die Verabschiedung «dieses \n\
Schlüsselelements zur Ernährungssicherheit», an dessen Formulierung die Schweiz\n \
aktiv beteiligt gewesen sei. Doch das BLW warnt auch: «Der Mehrwert der Richtlinien \n\
wird gemessen an der zukünftigen Verwendung in den Entwicklungsländern.» Die \n\
neuen Regeln haben einen grossen Haken: Sie sind freiwillig. Kein Staat, kein Konzern \n\
verpflichtet sich, sie einzuhalten.\n\n\
Schwache Länder im Fokus\n\n\
Allein China hat in Entwicklungsländern über 50 Milliarden Dollar für Landkäufe\n \
ausgegeben. Der grösste Einzeldeal fand im Kongo statt: 2,8 Millionen Hektaren für \n\
Palmöl-Plantagen. In Sierra Leone will China 1,2 Milliarden Dollar investieren, um Reis\n\
zu produzieren. Wenn den ärmsten Ländern der Welt solche Summen angeboten\n \
werden, kümmern sich deren Regierungen nicht um eine Handvoll Bauern vor Ort.\n\n\
«Investoren gehen gezielt in arme Länder, die schlecht in die Weltwirtschaft integriert \n\
sind, in denen oft Hunger herrscht und die schwache Institutionen der Landkontrolle \n\
haben», hält eine Analyse der Land-Matrix-Herausgeber fest. 70 Prozent der \n\
betroffenen Fläche befinden sich in Ostafrika sowie in Indonesien und auf den \n\
Philippinen. Aber auch Staaten in Südamerika kommen ins Visier von Landkäufern. \n\
Investoren kommen aus China und Indien, aus den Golfstaaten, aber auch aus \n\
Europa. Fonds im Agrobusiness gehören inzwischen zum Angebot vieler \n\
Investmentgesellschaften. Einen starken Schub solcher Investitionen gab es 2008, \n\
nachdem 2007 die Lebensmittelpreise weltweit sprunghaft gestiegen waren. Zwar sind \n\
die Preise inzwischen wieder etwas gesunken, doch der Trend sei ungebrochen, \n\
befindet die Land-Matrix-Analyse. Der legendäre US-Investor Warren Buffet erklärt seit \n\
Jahren, dass fruchtbares Ackerland das Gold der Zukunft sei.\n\n\
Kleinbauern schützen\n\n\
Investoren wollen eine hohe Rendite erzielen. Sie müssen deshalb aus den Böden das \n\
Maximum herausholen. Das bedeutet Monokulturen, hohen Pestizid- und \n\
Düngemitteleinsatz sowie einen enormen Wasserverbrauch – auch in Ländern, die \n\
schon heute unter Trockenheit leiden. Nestlé-Verwaltungsratspräsident Peter Brabeck \n\
hält die weltweit zunehmende Wasserknappheit für eines der grössten Probleme der \n\
Landwirtschaft überhaupt. Und er warnt, dass die Getreideproduktion deswegen in den \n\
nächsten 20 Jahren um ein Drittel zurückgehen könnte.\n\n\
Auch die FAO erachtet die Intensivierung der Landwirtschaft weltweit als \n\
unausweichlich. Noch ernähren Kleinbauern einen grossen Teil der Weltbevölkerung. \n\
Den Bedarf der rapide wachsenden Städte können sie aber langfristig nicht\n \
befriedigen. Umso wichtiger sei der Ausgleich zwischen den Bedürfnissen der\n \
industriellen Landwirtschaft und der Kleinbauern. Die FAO will in den nächsten \n\
Monaten Handbücher für Investoren in der Landwirtschaft erstellen. «Die neuen \n\
Leitlinien sind ein Anfang», sagt FAO-Generalsekretär José Graziano da Silva. «Sie \n\
werden helfen, die Lage der Armen und Hungrigen zu verbessern.»\n\
"
    }
    if(text==4)
    this.titleText="123456789012345678901234567890"
  },
    watch:{
      paper:function(){
        if(this.paper.length == this.titleText.length){
          this.paper=''
        }
      }
    }
  },
  
  props: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  margin-left: 30%;

  align-content: center;
  height: 80%;
  max-height: 100pt;
  width:60%; 
   max-width:60%; 
   display: inline-block;
   border-style: solid;
   border-radius: 0px;
  border: 10px;
  border-color: black;


}
.paper {
  align-content: center;
     padding: 5px;
     font-size: 16px;



}
.cont{
  margin-top: 5%;
  margin-left: 45%;
  margin-bottom: 10%;
  align-self: center;
}
</style>
